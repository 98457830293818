import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../../components/BreadCrumbs/BreadCrumbs";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CampaignsScreen from "./CampaignsScreen/CampaignsScreen";
import PriceScreen from "./PriceScreen/PriceScreen";

const CampaignsAndPrices = () => {
  const navigate = useNavigate();
  const funds = useSelector((state) => state.fundsAndCampaigns.funds).filter(
    (fund) => fund.showOnWebsite
  );
  const additionalCauses = useSelector(
    (state) => state.fundsAndCampaigns.additionalCauses
  );
  const { fundSlug, campaignSlug } = useParams();
  const currentFund =
    additionalCauses.find((cause) => cause.slug === fundSlug) ||
    funds.find((fund) => fund.slug === fundSlug);
  const fundName = currentFund.name;

  const campaigns = useSelector((state) => state.fundsAndCampaigns.campaigns);

  const campaignBlocks = useSelector((state) =>
    state.fundsAndCampaigns.campaignBlocks.filter(
      (c) => c.slug === campaignSlug
    )
  );

  const isAdditionalCause = additionalCauses.find(
    (cause) => cause.slug === fundSlug
  );
  const isCustomBlock = campaignBlocks.length > 0;

  const currentCampaign = isCustomBlock
    ? campaigns
        .filter((c) => c.campaignId == campaignBlocks[0].campaignId)
        .map((c) => ({
          ...c,
          fundId: campaignBlocks[0].showOnFundId,
          name: campaignBlocks[0].name
        }))[0]
    : campaigns.find(
        (campaign) =>
          (campaign.slug === campaignSlug &&
            campaign.fundId === currentFund.fundId) ??
          fundSlug
      ) ||
      additionalCauses.find(
        (cause) =>
          (cause.slug === campaignSlug &&
            cause.fundId === currentFund.fundId) ||
          fundSlug
      );
  const campaignName = currentCampaign?.name;

  const filteredCampaigns = campaigns.filter((campaign) =>
    campaign.showOnTheseFunds.includes(currentFund.fundId)
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <main id="primary" className="siteMain">
      <Breadcrumbs
        currentCampaign={currentCampaign}
        isCustomBlock={isCustomBlock}
        title={
          (currentFund.campaignId && currentFund.name.includes("where most")) ||
          campaignSlug
            ? campaignName
            : fundName
        }
        color={currentFund.color}
      />
      {(currentFund.campaignId && currentFund.name.includes("where most")) ||
      campaignSlug ? (
        <PriceScreen
          currentCampaign={currentCampaign}
          fundName={fundName}
          color={currentFund.color}
          isCustomBlock={isCustomBlock}
        />
      ) : (
        <CampaignsScreen
          currentFund={currentFund}
          fundName={fundName}
          funds={funds}
          fundSlug={fundSlug}
          navigate={navigate}
          filteredCampaigns={filteredCampaigns}
          isCustomBlock={isCustomBlock}
          isAdditionalCause={isAdditionalCause}
        />
      )}
    </main>
  );
};

export default CampaignsAndPrices;
