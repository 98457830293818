import React, { useState } from "react";
import "./FundAndCampaignCard.css";
import { useDispatch } from "react-redux";
import { setImage } from "../../store/slices/fundsAndCampaignsSlice";

export const hexToRgbA = (hex) => {
  if (!hex) {
    return null;
  }
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",");
  }
  throw new Error("Bad Hex");
};

const FundAndCampaignCard = ({
  name,
  description,
  color,
  hoverColor,
  iconSVGCode,
  image,
  staticBackground,
  outline,
  shadow,
  simple,
  onClick,
  noImageChange,
  contentColorIdleOnHover
}) => {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);

  const isMainMostNeeded =
    name?.trim().toLowerCase() === "where most needed & sadaqah";
  const isMostNeeded = name?.trim().toLowerCase() === "where most needed";
  return (
    <a
      className={
        isMainMostNeeded || isMostNeeded
          ? `causeOption analytics-main causeOption--active ${
              isMostNeeded && "causeOption--whereMostNeeded causeOption--cause"
            } ${
              isMainMostNeeded && "causeOption--where-most-needed"
            } causeOption--highlight`
          : simple
          ? "causeOption analytics-main causeOption--cause causeOption--gaza-emergency-response"
          : "causeOption analytics-main causeOption--program causeOption--gaza-emergency-response causeOption--hasImg"
      }
      style={{
        "--color": color,
        "--hexColor": hexToRgbA(color),
        "--img": `url(${image})`,
        "--desktop-img": `url(${image})`,
        ...((isMainMostNeeded || isMostNeeded) && {
          order: -1
        })
      }}
      onClick={onClick}
      onMouseEnter={() => {
        setIsHovered(true);
        if (!noImageChange) {
          dispatch(setImage(image));
        }
      }}
      onMouseLeave={() => setIsHovered(false)}
    >
      {!simple && (
        <div
          className="causeOption__bgOverlay"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: `rgba(${hexToRgbA(color)},0.7)`,
            position: "absolute",
            borderRadius: "24px",
            left: 0,
            top: 0,
            zIndex: 1
          }}
        ></div>
      )}
      <div
        className="causeOption__inner"
        style={
          !simple
            ? {
                position: "absolute",
                zIndex: 2,
                padding: '18px 20px'
              }
            : undefined
        }
      >
        {iconSVGCode && (
          <div
            className="svg-inline--fa fa-engine-warning causeOption__icon"
            dangerouslySetInnerHTML={{ __html: iconSVGCode }}
          />
        )}
        <span className="causeOption__name">{name}</span>
        {description && (
          <p className="custom-card-description">{description}</p>
        )}
      </div>
    </a>
  );
};

export default FundAndCampaignCard;
