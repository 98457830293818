import React from "react";
import "./MoreWaysToDonate.css";
import CustomButton from "../CustomButton/CustomButton";
import { hexToRgbA } from "../FundAndCampaignCard/FundAndCampaignCard";
import { Link } from "react-router-dom";

const MoreWaysToDonate = ({ color, handleClick }) => {
  return (
    <div
      className="mostNeededCTA"
      style={{ "--hexColor": hexToRgbA(color), "--color": color }}
    >
      <div className="wrapper mostNeededCTA__inner">
        <h2 className="mostNeededCTA__title"> More Ways to Donate </h2>
        <a
          onClick={handleClick}
          className="mostNeededCTA__btn"
          style={{ "--color": color }}
        >
          Donate To Most Needed
        </a>
        <p className="mostNeededCTA__desc">
          {" "}
          We'll make sure your donation goes to the communities who need it
          most.{" "}
        </p>
      </div>
    </div>
  );
};

export default MoreWaysToDonate;
