import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./BreadCrumbs.css";
import { FaUndoAlt } from "react-icons/fa";
import { hexToRgbA } from "../FundAndCampaignCard/FundAndCampaignCard";

const Breadcrumbs = ({ title, color, currentCampaign }) => {
  const { fundSlug, campaignSlug } = useParams();
  const navigate = useNavigate();
  const funds = useSelector((state) => state.fundsAndCampaigns.funds);
  const additionalCauses = useSelector(
    (state) => state.fundsAndCampaigns.additionalCauses
  );
  const currentFund =
    funds.find((fund) => fund.slug === fundSlug) ||
    additionalCauses.find((fund) => fund.slug === fundSlug);
  const fundName = currentFund ? currentFund.name.toUpperCase() : "CHECKOUT";

  const campaignName = currentCampaign
    ? currentCampaign.name.toUpperCase()
    : "Unknown Campaign";

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={`pageHeader pageHeader--${
        fundSlug === "where-most-needed"
          ? "cause"
          : campaignSlug
          ? "cause"
          : "program"
      }`}
      style={{
        "--img": `url(${currentFund?.image})`,
        "--color": color || "",
        "--hexColor": color ? hexToRgbA(color) : ""
      }}
    >
      <div className="pageHeader__inner">
        <a
          onClick={handleGoBack}
          className={`btn--tertiary  ${
            fundSlug === "where-most-needed"
              ? "btn--lightGrey"
              : !campaignSlug
              ? "btn--white"
              : "btn--lightGrey"
          } pageHeader__goBack`}
        >
          Go Back{" "}
          <svg
            className="svg-inline--fa fa-undo"
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="undo"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            data-fa-i2svg=""
          >
            <path
              fill="currentColor"
              d="M40 224c-13.3 0-24-10.7-24-24L16 56c0-13.3 10.7-24 24-24s24 10.7 24 24l0 80.1 20-23.5C125 63.4 186.9 32 256 32c123.7 0 224 100.3 224 224s-100.3 224-224 224c-50.4 0-97-16.7-134.4-44.8c-10.6-8-12.7-23-4.8-33.6s23-12.7 33.6-4.8C179.8 418.9 216.3 432 256 432c97.2 0 176-78.8 176-176s-78.8-176-176-176c-54.3 0-102.9 24.6-135.2 63.4l-.1 .2s0 0 0 0L93.1 176l90.9 0c13.3 0 24 10.7 24 24s-10.7 24-24 24L40 224z"
            ></path>
          </svg>
        </a>

        <ul className="breadcrumbs">
          <li className="breadcrumb">
            <Link to="/">Main</Link>
          </li>

          {campaignSlug ? (
            <li className="breadcrumb">
              <Link to={`/program/${fundSlug}`}>{fundName}</Link>
            </li>
          ) : (
            <li className="breadcrumb">
              <span className="breadcrumb__active">{fundName}</span>
            </li>
          )}
          {campaignSlug && (
            <li className="breadcrumb">
              <span className="breadcrumb__active">{campaignName}</span>
            </li>
          )}
        </ul>
        <h1
          className="pageHeader__title"
          style={{
            color:
              fundSlug === "where-most-needed" || campaignSlug
                ? color
                : undefined
          }}
        >
          {title} {!campaignSlug && <span>Causes</span>}
        </h1>
        {/* <Link to="/" className="breadcrumb-link main">
          MAIN
        </Link>
        <span className="breadcrumb-divider">/</span>
        {campaignSlug ? (
          <Link to={`/program/${fundSlug}`} className="breadcrumb-link">
            {fundName}
          </Link>
        ) : (
          <span className="breadcrumb-active">{fundName}</span>
        )}
        {campaignSlug && (
          <>
            <span className="breadcrumb-divider">/</span>
            <span className="breadcrumb-active">{campaignName}</span>
          </>
        )} */}
      </div>
    </div>
  );
};

export default Breadcrumbs;
