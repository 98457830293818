import React, { useRef, useEffect, useState, useCallback } from "react";
import "./Checkout.css";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import BreadCrumbs from "../../../../../components/BreadCrumbs/BreadCrumbs";
import CustomCheckbox from "../../../../../components/CustomCheckbox/CustomCheckbox";
import CustomSpinner from "../../../../../components/CustomSpinner/CustomSpinner";
import Upsell from "../../../../../components/Upsell/Upsell";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import {
  MdLock,
  MdPerson,
  MdEmail,
  MdPhone,
  MdLocationOn,
  MdLocationCity,
  MdDoorBack,
  MdMap,
  MdMarkunreadMailbox,
  MdPublic,
  MdOutlinePending
} from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import CustomButton from "../../../../../components/CustomButton/CustomButton";
import { useSearchParams } from "react-router-dom";
import { setImage } from "../../../../../store/slices/fundsAndCampaignsSlice";
import { useLoadScript } from "@react-google-maps/api";
import {
  verifyEmail,
  verifyRecaptcha,
  verifyPhone
} from "../../../../../api/verification";
import {
  CardElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
  ExpressCheckoutElement
} from "@stripe/react-stripe-js";
import {
  createPaymentIntent,
  createSubscriptions,
  verifyPaymentIntent,
  updatePaymentIntent,
  createSetupIntent
} from "../../../../../api/payments";
import { showErrorToast } from "../../../../../utils/toastify";
import slugify from "slugify";
import { shouldRenderUpsell } from "../../../../../utils/misc";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [paymentMethod, setPaymentMethod] = useState("Credit Card");
  const [isZakatSelected, setIsZakatSelected] = useState(false);
  const [optOutOfEmailsSelected, setOptOutOfEmailsSelected] = useState(false);
  const [isProcessingFeeSelected, setIsProcessingFeeSelected] = useState(false);
  const [isUpsellSelected, setUpsellChecked] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [inscription, setInscription] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const donation = decodeURIComponent(searchParams.get("donation") || "");
  const program = decodeURIComponent(searchParams.get("program") || "");
  const custom = decodeURIComponent(searchParams.get("custom") || "");
  const re_split = decodeURIComponent(searchParams.get("re_split") || "");
  const amount = searchParams.get("amount") || "";
  const frequency = decodeURIComponent(searchParams.get("frequency") || "");
  const details = decodeURIComponent(searchParams.get("details") || "");

  const funds = useSelector((state) => state.fundsAndCampaigns.funds);

  const campaignBlocks = useSelector((state) =>
    state.fundsAndCampaigns.campaignBlocks
      .filter((c) => {
        return (
          c?.slug.trim().toLowerCase() ===
          slugify(donation.trim().toLocaleLowerCase())
        );
      })
      .map((c) => ({
        ...c,
        fundId: state.fundsAndCampaigns.campaignBlocks[0].showOnFundId,
        name: state.fundsAndCampaigns.campaignBlocks[0].name
      }))
  );

  const isCustomBlock = campaignBlocks.length > 0;

  const additionalCauses = useSelector(
    (state) => state.fundsAndCampaigns.additionalCauses
  );
  const currentFund =
    funds.find((filter) => filter.name === program) ||
    additionalCauses.find((filter) => filter.name === program);

  const campaigns = useSelector((state) => state.fundsAndCampaigns.campaigns);
  const currentCampaigns =
    campaigns.filter((campaign) => campaign.name === donation) ||
    additionalCauses.filter((campaign) => campaign.name === donation);
  const currentCampaign = isCustomBlock
    ? campaigns.filter((c) => c.campaignId == campaignBlocks[0].campaignId)[0]
    : currentCampaigns.find(
        (campaign) => campaign.fundId === currentFund.fundId
      );

  console.log(currentCampaign);
  const processingFee = (amount * 0.022 + 0.8).toFixed(2);
  const mainTotal = (
    parseFloat(amount) +
    (isProcessingFeeSelected ? parseFloat(processingFee) : 0)
  ).toFixed(2);
  const upsellAmount =
    isUpsellSelected && currentCampaign?.upsell
      ? parseFloat(currentCampaign?.upsell?.amount)
      : 0;
  const total = (parseFloat(mainTotal) + upsellAmount).toFixed(2);

  const handleGoogleApplePay = useCallback(
    async (event) => {
      try {
        if (!paymentIntentId || !clientSecret) {
          showErrorToast(
            "Payment intent could not be found. Please try again."
          );
          event.complete("fail");
          return;
        }

        await updatePaymentIntent(paymentIntentId, {
          isZakat: isZakatSelected,
          re_split: re_split,
          optOutOfEmails: optOutOfEmailsSelected,
          processingFee: isProcessingFeeSelected ? parseFloat(processingFee) : 0
        });

        const { error, paymentIntent } = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: event.paymentMethod.id
          }
        );

        if (error) {
          console.log("Payment failed:", error.message);
          showErrorToast("Payment failed. Please try again.");
          event.complete("fail");
          return;
        }

        if (paymentIntent?.status === "succeeded") {
          event.complete("success");

          const donorDetails = {
            firstName: event.billing_details.name?.split(" ")[0],
            lastName: event.billing_details.name?.split(" ").slice(1).join(" "),
            email: event.billing_details.email,
            phone: event.billing_details.phone,
            addressLine1: event.billing_details.address.line1,
            addressLine2: event.billing_details.address.line2 || "",
            city: event.billing_details.address.city,
            postalCode: event.billing_details.address.postal_code,
            region: event.billing_details.address.state,
            country: event.billing_details.address.country,
            optOutOfEmails: optOutOfEmailsSelected
          };

          if (isSubscription) {
            const subscriptionResponse = await createSubscriptions({
              paymentMethodId: paymentIntent.payment_method,
              isZakat: isZakatSelected,
              re_split: re_split,
              campaignId: currentCampaign.campaignId,
              fundId: currentFund.fundId,
              inscription: inscription || undefined,
              processingFee: isProcessingFeeSelected ? processingFee : 0,
              donorDetails
            });

            navigate(
              `/thank-you/${currentFund.slug}/${
                currentCampaign.slug
              }?firstName=${encodeURIComponent(
                formValues.firstName + " " + formValues.lastName ||
                  event.payerName
              )}&amount=${encodeURIComponent(
                amount
              )}&subscriptionIds=${subscriptionResponse.subscriptions
                .map((s) => s.subscriptionId)
                .join(",")}`
            );
          } else {
            navigate(
              `/thank-you/${currentFund.slug}/${
                currentCampaign.slug
              }?firstName=${encodeURIComponent(
                formValues.firstName + " " + formValues.lastName ||
                  event.payerName
              )}&amount=${encodeURIComponent(amount)}&paymentIntentId=${
                paymentIntent.id
              }`
            );
          }
        } else {
          showErrorToast("Payment not completed. Please try again.");
          event.complete("fail");
        }
      } catch (err) {
        console.log("Error processing Google/Apple Pay:", err);
        showErrorToast("An error occurred. Please try again.");
        event.complete("fail");
      }
    },
    [
      paymentIntentId,
      clientSecret,
      isZakatSelected,
      optOutOfEmailsSelected,
      isProcessingFeeSelected,
      processingFee,
      stripe,
      amount,
      isSubscription,
      navigate,
      updatePaymentIntent,
      createSubscriptions
    ]
  );

  useEffect(() => {
    dispatch(setImage("/assets/bg-checkout.jpg"));
  }, []);

  useEffect(() => {
    if (frequency !== "One Time") {
      setIsSubscription(true);
    } else {
      initializePaymentIntent();
    }
  }, []);

  console.log("is subscription", isSubscription);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "CA",
        currency: "cad",
        total: {
          label: "Total",
          amount: Math.round(total * 100) // Convert to cents and ensure it's an integer
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe, total]);

  useEffect(() => {
    if (paymentRequest) {
      const handlePaymentMethod = handleGoogleApplePay;

      paymentRequest.off("paymentmethod", handlePaymentMethod); // Ensure no duplicate handlers
      paymentRequest.on("paymentmethod", handlePaymentMethod);

      return () => {
        paymentRequest.off("paymentmethod", handlePaymentMethod);
      };
    }
  }, [paymentRequest, handleGoogleApplePay]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.update({
        total: {
          label: "Total",
          amount: Math.round(total * 100) // Convert to cents and ensure it's an integer
        }
      });
    }
  }, [paymentRequest, total]);

  const parseDetails = (input) => {
    if (typeof input !== "string") {
      console.error("parseDetails: Input is not a string", input);
      return [];
    }

    return input
      .split(";")
      .map((entry, index) => {
        const trimmedEntry = entry.trim();
        const regex = /^(.*?)\s*X\s*(\d+)\s*\(\$(\d+(?:\.\d{1,2})?)\)$/;
        const match = trimmedEntry.match(regex);
        if (match) {
          const [, description, count, amount] = match;
          console.log(`parseDetails: Entry ${index + 1} parsed successfully.`, {
            description,
            count: Number(count),
            amount: Number(amount)
          });
          return {
            description: description.trim(),
            count: Number(count),
            amount: Number(amount)
          };
        } else {
          console.warn(
            `parseDetails: Entry ${
              index + 1
            } did not match the pattern: "${trimmedEntry}"`
          );
          return null;
        }
      })
      .filter(Boolean);
  };

  const initializePaymentIntent = async () => {
    try {
      const existingPaymentIntentId = localStorage.getItem("paymentIntentId");

      if (existingPaymentIntentId) {
        // Verify the existing payment intent status
        const paymentIntentResponse = await verifyPaymentIntent(
          existingPaymentIntentId
        );

        if (
          paymentIntentResponse.status &&
          paymentIntentResponse.status !== "succeeded" &&
          paymentIntentResponse.status !== "canceled"
        ) {
          setClientSecret(paymentIntentResponse.clientSecret);
          setPaymentIntentId(existingPaymentIntentId);
          return;
        }

        // Clear local storage if the intent was succeeded or canceled
        localStorage.removeItem("paymentIntentId");
      }

      // Create a new payment intent
      const newPaymentIntent = await createPaymentIntent({
        amount: parseInt(mainTotal),
        details: parseDetails(details),
        fundId: currentCampaigns[0]?.fundId,
        campaignId: currentCampaigns[0]?.campaignId,
        re_split: re_split
      });

      setClientSecret(newPaymentIntent.clientSecret);
      setPaymentIntentId(newPaymentIntent.paymentIntentId);

      // Store the new payment intent ID in local storage
      localStorage.setItem("paymentIntentId", newPaymentIntent.paymentIntentId);
    } catch (err) {
      console.log("Error initializing payment intent:", err);
    }
  };

  const processUpsellPayment = async (
    customerId,
    paymentMethodId,
    parentSubscriptionId = null
  ) => {
    try {
      const upsellResponse = await createPaymentIntent({
        amount: parseFloat(upsellAmount),
        details: [
          { description: "Upsell", amount: parseFloat(upsellAmount), count: 1 }
        ],
        customerId: customerId
      });

      // Update the upsell payment intent with customer details and link to the main payment intent
      await updatePaymentIntent(upsellResponse.paymentIntentId, {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: formValues.email,
        phone: formValues.phone,
        addressLine1: formValues.addressLine1,
        addressLine2: formValues.addressLine2,
        unit: formValues.unitNumber,
        city: formValues.city,
        postalCode: formValues.postalCode,
        region: formValues.selectedRegion,
        country: formValues.selectedCountry,
        amount: parseFloat(upsellAmount),
        processingFee: 0,
        parentPaymentIntentId: paymentIntentId,
        parentSubscriptionId: parentSubscriptionId
      });

      // Confirm the upsell payment
      const upsellPaymentResult = await stripe.confirmCardPayment(
        upsellResponse.clientSecret,
        {
          payment_method: paymentMethodId
        }
      );

      if (upsellPaymentResult.paymentIntent.status === "succeeded") {
        console.log("Upsell payment succeeded");
      } else {
        showErrorToast("Upsell payment failed. Please try again.");
      }
    } catch (error) {
      console.log("Error processing upsell payment:", error);
      showErrorToast("Error processing upsell payment. Please try again.");
    }
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["places"]
  });

  const [formValues, setFormValues] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    addressLine1: "",
    addressLine2: "",
    unitNumber: "",
    city: "",
    postalCode: "",
    selectedRegion: "",
    selectedCountry: "CA"
  });
  const [emailErrorMessage, setEmailErrorMessage] = useState(null);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState(null);

  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!formValues.firstName) {
      isValid = false;
      errors.firstName = "First name is required";
    }

    if (!formValues.lastName) {
      isValid = false;
      errors.lastName = "Last name is required";
    }

    if (!formValues.email) {
      isValid = false;
      errors.email = "Email is required";
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formValues.email)) {
        isValid = false;
        errors.email = "Invalid email format";
      }
    }

    if (!formValues.phone) {
      isValid = false;
      errors.phone = "Phone number is required";
    }

    if (!formValues.addressLine1) {
      isValid = false;
      errors.addressLine1 = "Address Line 1 is required";
    }

    if (formValues.unitNumber && isNaN(formValues.unitNumber)) {
      isValid = false;
      errors.unitNumber = "Unit number must be a number";
    }

    if (!formValues.city) {
      isValid = false;
      errors.city = "City is required";
    }

    if (!formValues.postalCode) {
      isValid = false;
      errors.postalCode = "Postal code is required";
    }

    if (!formValues.selectedRegion) {
      isValid = false;
      errors.selectedRegion = "Region is required";
    }

    if (!formValues.selectedCountry) {
      isValid = false;
      errors.selectedCountry = "Country is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleDonateClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validateForm()) {
      window.scrollTo(0, 0);
      setLoading(false);
      return;
    }

    if (!executeRecaptcha) {
      console.log("reCAPTCHA is not ready");
      setLoading(false);
      return;
    }

    const token = await executeRecaptcha("checkout_action");

    try {
      const result = await verifyRecaptcha(token);

      if (result.success && result.score >= 0.5) {
        if (!stripe || !elements) {
          console.log("Stripe or elements not loaded");
          setLoading(false);
          return;
        }

        const isSubscriptionPayment = isSubscription;

        if (paymentMethod === "Online Banking") {
          if (isSubscriptionPayment) {
            let frequencyKey = frequency;
            if (
              isProcessingFeeSelected &&
              (frequency === "monthly" || frequency === "annually")
            ) {
              frequencyKey = frequency + "WithProcessingFee";
            }

            const parsedDetails = parseDetails(details);
            const priceIds = [];
            parsedDetails.forEach((detail) => {
              const priceIdLocal =
                currentCampaign.priceOptions.pricePoints.find(
                  (pricePoint) =>
                    parseInt(pricePoint.amount) === parseInt(detail.amount)
                ).stripePriceIds[frequencyKey];
              const count = detail.count;
              for (let i = 0; i < count; i++) {
                priceIds.push(priceIdLocal);
              }
            });

            const intervalDescription = `You authorize us to debit your bank account for ${frequency.toLowerCase()} payments of $${parseFloat(
              amount
            ).toFixed(2)}.`;

            const { clientSecret: setupIntentClientSecret } =
              await createSetupIntent({
                paymentMethodType: "acss_debit",
                donorDetails: {
                  firstName: formValues.firstName,
                  lastName: formValues.lastName,
                  email: formValues.email,
                  phone: formValues.phone,
                  addressLine1: formValues.addressLine1,
                  addressLine2: formValues.addressLine2,
                  unitNumber: formValues.unitNumber,
                  city: formValues.city,
                  postalCode: formValues.postalCode,
                  region: formValues.selectedRegion,
                  country: formValues.selectedCountry,
                  optOutOfEmails: optOutOfEmailsSelected
                },
                intervalDescription
              });

            const { error: confirmError, setupIntent } =
              await stripe.confirmAcssDebitSetup(setupIntentClientSecret, {
                payment_method: {
                  billing_details: {
                    name: `${formValues.firstName} ${formValues.lastName}`,
                    email: formValues.email,
                    phone: formValues.phone,
                    address: {
                      line1: formValues.addressLine1,
                      line2: formValues.unitNumber
                        ? `Unit ${formValues.unitNumber}, ${formValues.addressLine2}`
                        : formValues.addressLine2,
                      city: formValues.city,
                      state: formValues.selectedRegion,
                      postal_code: formValues.postalCode,
                      country: formValues.selectedCountry
                    }
                  }
                }
              });

            if (confirmError) {
              console.log(
                "Error confirming SetupIntent:",
                confirmError.message
              );
              showErrorToast(confirmError.message);
              setLoading(false);
              return;
            }

            if (
              setupIntent.status === "succeeded" ||
              setupIntent.status === "processing"
            ) {
              const response = await createSubscriptions({
                priceIds,
                paymentMethodId: setupIntent.payment_method,
                isZakat: isZakatSelected,
                re_split: re_split,
                campaignId: currentCampaign.campaignId,
                fundId: currentFund.fundId,
                inscription: inscription || undefined,
                processingFee: isProcessingFeeSelected ? processingFee : 0,
                donorDetails: {
                  firstName: formValues.firstName,
                  lastName: formValues.lastName,
                  email: formValues.email,
                  phone: formValues.phone,
                  addressLine1: formValues.addressLine1,
                  addressLine2: formValues.addressLine2,
                  unitNumber: formValues.unitNumber,
                  city: formValues.city,
                  postalCode: formValues.postalCode,
                  region: formValues.selectedRegion,
                  country: formValues.selectedCountry,
                  optOutOfEmails: optOutOfEmailsSelected
                }
              });

              const { subscriptions, customerId } = response;

              if (isUpsellSelected) {
                await processUpsellPayment(
                  customerId,
                  setupIntent.payment_method,
                  response.subscriptions[0].subscriptionId
                );
              }

              navigate(
                `/thank-you/${currentFund.slug}/${
                  currentCampaign.slug
                }?firstName=${encodeURIComponent(
                  formValues.firstName + " " + formValues.lastName
                )}&amount=${encodeURIComponent(
                  amount
                )}&subscriptionIds=${subscriptions
                  .map((subscription) => subscription.subscriptionId)
                  .join(",")}`
              );
            } else {
              showErrorToast("Setup failed. Please try again.");
              setLoading(false);
              return;
            }
          } else {
            const { clientSecret } = await createPaymentIntent({
              amount: parseFloat(mainTotal),
              details: parseDetails(details),
              paymentMethodType: "acss_debit"
            });

            const { error: confirmError, paymentIntent } =
              await stripe.confirmAcssDebitPayment(clientSecret, {
                payment_method: {
                  billing_details: {
                    name: `${formValues.firstName} ${formValues.lastName}`,
                    email: formValues.email,
                    phone: formValues.phone,
                    address: {
                      line1: formValues.addressLine1,
                      line2: formValues.unitNumber
                        ? `Unit ${formValues.unitNumber}, ${formValues.addressLine2}`
                        : formValues.addressLine2,
                      city: formValues.city,
                      state: formValues.selectedRegion,
                      postal_code: formValues.postalCode,
                      country: formValues.selectedCountry
                    }
                  }
                }
              });

            if (confirmError) {
              console.log(
                "Error confirming PaymentIntent:",
                confirmError.message
              );
              showErrorToast(confirmError.message);
              setLoading(false);
              return;
            }

            if (
              paymentIntent.status === "succeeded" ||
              paymentIntent.status === "processing"
            ) {
              navigate(
                `/thank-you/${currentFund.slug}/${
                  currentCampaign.slug
                }?firstName=${encodeURIComponent(
                  formValues.firstName + " " + formValues.lastName
                )}&amount=${encodeURIComponent(amount)}&paymentIntentId=${
                  paymentIntent.id
                }`
              );
            } else {
              showErrorToast("Payment failed. Please try again.");
              setLoading(false);
              return;
            }
          }
        } else {
          const cardElement = elements.getElement(CardElement);

          const { paymentMethod, error: paymentMethodError } =
            await stripe.createPaymentMethod({
              type: "card",
              card: cardElement
            });

          if (paymentMethodError) {
            showErrorToast(paymentMethodError.message);
            setLoading(false);
            return;
          }

          const paymentMethodId = paymentMethod?.id;

          if (!paymentMethodId) {
            showErrorToast("Payment method creation failed. Please try again.");
            setLoading(false);
            return;
          }

          if (!isSubscription) {
            try {
              await updatePaymentIntent(paymentIntentId, {
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                email: formValues.email,
                phone: formValues.phone,
                addressLine1: formValues.addressLine1,
                addressLine2: formValues.addressLine2,
                unit: formValues.unitNumber,
                city: formValues.city,
                postalCode: formValues.postalCode,
                region: formValues.selectedRegion,
                country: formValues.selectedCountry,
                amount: parseFloat(mainTotal),
                processingFee: isProcessingFeeSelected
                  ? parseFloat(processingFee)
                  : 0,
                isZakat: isZakatSelected,
                re_split: re_split,
                optOutOfEmails: optOutOfEmailsSelected
              });

              const paymentResult = await stripe.confirmCardPayment(
                clientSecret,
                {
                  payment_method: paymentMethodId,
                  setup_future_usage: "off_session"
                }
              );

              if (paymentResult.paymentIntent.status === "succeeded") {
                localStorage.removeItem("paymentIntentId");

                const customerId = paymentResult.paymentIntent.customer;

                if (isUpsellSelected) {
                  await processUpsellPayment(customerId, paymentMethodId);
                }

                navigate(
                  `/thank-you/${currentFund.slug}/${
                    currentCampaign.slug
                  }?firstName=${encodeURIComponent(
                    formValues.firstName + " " + formValues.lastName
                  )}&amount=${encodeURIComponent(amount)}&paymentIntentId=${
                    paymentResult.paymentIntent.id
                  }`
                );
              } else {
                showErrorToast("Payment failed. Please try again.");
                setLoading(false);
                return;
              }
            } catch (error) {
              console.log("Error updating payment intent:", error);
              showErrorToast(
                "Failed to update payment details. Please try again."
              );
              setLoading(false);
              return;
            }
          } else {
            let frequencyKey = frequency;
            if (
              isProcessingFeeSelected &&
              (frequency === "monthly" || frequency === "annually")
            ) {
              frequencyKey = frequency + "WithProcessingFee";
            }

            const parsedDetails = parseDetails(details);

            const priceIds = [];
            parsedDetails.forEach((detail) => {
              const priceIdLocal =
                currentCampaign.priceOptions.pricePoints.find(
                  (pricePoint) =>
                    parseInt(pricePoint.amount) === parseInt(detail.amount)
                ).stripePriceIds[frequencyKey];
              const count = detail.count;
              for (let i = 0; i < count; i++) {
                priceIds.push(priceIdLocal);
              }
            });

            try {
              const response = await createSubscriptions({
                priceIds,
                paymentMethodId,
                isZakat: isZakatSelected,
                campaignId: currentCampaign.campaignId,
                fundId: currentFund.fundId,
                inscription: inscription || undefined,
                re_split,
                processingFee: isProcessingFeeSelected ? processingFee : 0,
                donorDetails: {
                  firstName: formValues.firstName,
                  lastName: formValues.lastName,
                  email: formValues.email,
                  phone: formValues.phone,
                  addressLine1: formValues.addressLine1,
                  addressLine2: formValues.addressLine2,
                  unit: formValues.unitNumber,
                  city: formValues.city,
                  postalCode: formValues.postalCode,
                  region: formValues.selectedRegion,
                  country: formValues.selectedCountry,
                  optOutOfEmails: optOutOfEmailsSelected
                }
              });

              const { subscriptions, customerId } = response;

              if (isUpsellSelected) {
                await processUpsellPayment(
                  customerId,
                  paymentMethodId,
                  response.subscriptions[0].subscriptionId
                );
              }

              navigate(
                `/thank-you/${currentFund.slug}/${
                  currentCampaign.slug
                }?firstName=${encodeURIComponent(
                  formValues.firstName + " " + formValues.lastName
                )}&amount=${encodeURIComponent(
                  amount
                )}&subscriptionIds=${subscriptions
                  .map((subscription) => subscription.subscriptionId)
                  .join(",")}`
              );
            } catch (error) {
              showErrorToast(
                "Error creating one or more subscriptions. Please try again."
              );
              console.log("Subscription Error:", error);
              setLoading(false);
            }
          }
        }
      } else {
        alert("reCAPTCHA verification failed. Please try again.");
        setLoading(false);
      }
    } catch (apiError) {
      showErrorToast("Error processing payment. Please try again.");
      console.log("API Error:", apiError.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <CheckoutHeader
        amount={amount}
        frequency={frequency}
        program={program}
        donation={donation}
        details={details}
      />

      <div className="checkout-body">
        <PaymentMethodSelection
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
        />
        {paymentMethod === "Credit Card" && <CreditCardInformation />}
        {paymentMethod !== "Mobile Pay" &&
          paymentMethod !== "Pay with Link" && (
            <Details
              isLoaded={isLoaded}
              formValues={formValues}
              setFormValues={setFormValues}
              emailErrorMessage={emailErrorMessage}
              setEmailErrorMessage={setEmailErrorMessage}
              phoneErrorMessage={phoneErrorMessage}
              setPhoneErrorMessage={setPhoneErrorMessage}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
          )}
        {currentCampaign?.allowInscription && (
          <Inscription
            inscription={inscription}
            setInscription={setInscription}
          />
        )}
        <ExtraChoice
          zakatEligible={currentCampaign.zakatEligible}
          isZakatSelected={isZakatSelected}
          setIsZakatSelected={setIsZakatSelected}
          optOutOfEmailsSelected={optOutOfEmailsSelected}
          setOptOutOfEmailsSelected={setOptOutOfEmailsSelected}
          isProcessingFeeSelected={isProcessingFeeSelected}
          setIsProcessingFeeSelected={setIsProcessingFeeSelected}
        />

        {shouldRenderUpsell(currentCampaign) && (
          <Upsell
            isUpsellSelected={isUpsellSelected}
            setUpsellChecked={setUpsellChecked}
            upsell={currentCampaign.upsell || null}
          />
        )}
        <SubTotal
          isProcessingFeeSelected={isProcessingFeeSelected}
          isUpsellSelected={isUpsellSelected}
          upsell={currentCampaign?.upsell || null}
          handleDonateClick={handleDonateClick}
          amount={amount}
          processingFee={processingFee}
          total={total}
          loading={loading}
          paymentRequest={paymentRequest}
          paymentMethod={paymentMethod}
        />
      </div>
      <Notes />
    </>
  );
};

export default Checkout;

const CheckoutHeader = ({ amount, frequency, donation, program, details }) => {
  return (
    <div className="checkoutHeader">
      <a
        href="https://donate.pennyappeal.ca/"
        class="btn--tertiary btn--grey pageHeader__goBack"
      >
        Go Back{" "}
        <svg
          class="svg-inline--fa fa-undo"
          aria-hidden="true"
          focusable="false"
          data-prefix="far"
          data-icon="undo"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M40 224c-13.3 0-24-10.7-24-24L16 56c0-13.3 10.7-24 24-24s24 10.7 24 24l0 80.1 20-23.5C125 63.4 186.9 32 256 32c123.7 0 224 100.3 224 224s-100.3 224-224 224c-50.4 0-97-16.7-134.4-44.8c-10.6-8-12.7-23-4.8-33.6s23-12.7 33.6-4.8C179.8 418.9 216.3 432 256 432c97.2 0 176-78.8 176-176s-78.8-176-176-176c-54.3 0-102.9 24.6-135.2 63.4l-.1 .2s0 0 0 0L93.1 176l90.9 0c13.3 0 24 10.7 24 24s-10.7 24-24 24L40 224z"
          ></path>
        </svg>
      </a>
      <ul className="breadcrumbs">
        <li className="breadcrumb">
          <a href="/">Main</a>{" "}
        </li>

        <li className="breadcrumb">
          <span className="breadcrumb__active">Checkout</span>{" "}
        </li>
      </ul>
      <h1 className="checkoutHeader__title">
        <span>You're about to donate</span> ${parseInt(amount)} (
        {frequency ? frequency : "One Time"}){" "}
      </h1>

      <p className="checkoutHeader__message">
        Thank you for making a difference to communities at home and across the
        world. Please fill out the form below to donate.
      </p>

      <div className="checkoutBreakdown">
        <div
          id="checkoutBreakdownList"
          className="checkoutBreakdown__list checkoutBreakdown__list--active"
          style={{ display: "block" }}
        >
          <p>
            <strong>Program:</strong> {program} <br />
            <strong>Cause:</strong> {donation} <br />
            <strong>Frequency:</strong> {frequency ? frequency : "One Time"}{" "}
          </p>

          <h4>Donation Breakdown</h4>
          <p>
            {details.split(";").map((detail, index) => {
              return <span key={index}>{detail}</span>;
            })}
          </p>
        </div>
      </div>
    </div>
  );
};

const PaymentMethodSelection = ({ paymentMethod, setPaymentMethod }) => {
  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  return (
    <div className="payment-selection-container">
      <div
        className="title"
        style={{ color: "#ef7c00", fontSize: "2.8rem", textAlign: "center" }}
      >
        Payment Details
      </div>
      <div
        className="payment-selection-select-payment-method "
        style={{
          color: "#55504F",
          textAlign: "center",
          fontSize: "2.1rem",
          fontWeight: 600
        }}
      >
        Select Payment Method
      </div>

      <div className="payment-selection-payment-optionsContainer">
        <div className="payment-selection-payment-option">
          <input
            className="payment-selection-payment-option"
            name="input_8"
            type="radio"
            value="Credit Card"
            checked={paymentMethod === "Credit Card"}
            id="choice_1_8_0"
            onChange={handlePaymentMethodChange}
          />
          <label
            htmlFor="choice_1_8_0"
            className="payment-selection-inline-label"
          >
            CREDIT CARD
            <img src="/assets/visa.svg" alt="Visa" />
          </label>
        </div>
        <div className="payment-selection-payment-option">
          <input
            className="payment-selection-payment-option"
            name="input_8"
            type="radio"
            value="Mobile Pay"
            checked={paymentMethod === "Mobile Pay"}
            id="choice_1_8_1"
            onChange={handlePaymentMethodChange}
          />
          <label
            htmlFor="choice_1_8_1"
            className="payment-selection-inline-label"
          >
            GOOGLE/APPLE PAY
          </label>
        </div>
        <div className="payment-selection-payment-option">
          <input
            className="payment-selection-payment-option"
            name="input_8"
            type="radio"
            value="Online Banking"
            checked={paymentMethod === "Online Banking"}
            id="choice_1_8_2"
            onChange={handlePaymentMethodChange}
          />
          <label
            htmlFor="choice_1_8_2"
            className="payment-selection-inline-label"
          >
            ONLINE BANKING
            <img src="/assets/credtcard.svg" alt="Credit Card" />
          </label>
        </div>
      </div>
    </div>
  );
};

const CustomPaymentMethod = ({ paymentRequest, total }) => {
  if (!paymentRequest) {
    return (
      <div className="payment-request-wrapper errorbox">
        <p className="payment-request-unavailable">
          Google/Apple Pay is not available on this device/browser.
        </p>
      </div>
    );
  }

  return (
    <div className="payment-request-wrapper" style={{ marginTop: "1rem" }}>
      <PaymentRequestButtonElement options={{ paymentRequest }} />
    </div>
  );
};

const CreditCardInformation = () => {
  const cardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        backgroundColor: "#fff",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };
  return (
    <div style={{ textAlign: "center" }}>
      <h3>Credit Card Information</h3>
      <div style={{ marginBottom: "1em", textAlign: "center" }}>
        <MdLock className="lock-icon" /> This is a secure SSL encrypted payment.
      </div>
      <div className="card-element-wrapper">
        <CardElement options={cardElementOptions} />
      </div>
    </div>
  );
};

const Details = ({
  isLoaded,
  formValues,
  setFormValues,
  emailErrorMessage,
  setEmailErrorMessage,
  phoneErrorMessage,
  setPhoneErrorMessage,
  formErrors,
  setFormErrors
}) => {
  const addressInputRef = useRef(null);
  const [emailLoading, setEmailLoading] = useState(false);
  const [phoneLoading, setPhoneLoading] = useState(false);

  useEffect(() => {
    if (isLoaded && addressInputRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        addressInputRef.current,
        {
          types: ["address"]
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        const addressComponents = place.address_components;
        let streetNumber = "";
        let route = "";
        let postal = "";
        let city = "";
        let unit = "";
        let region = "";
        let country = "";

        addressComponents.forEach((component) => {
          const types = component.types;
          if (types.includes("street_number"))
            streetNumber = component.long_name;
          if (types.includes("route")) route = component.long_name;
          if (types.includes("postal_code")) postal = component.long_name;
          if (types.includes("locality")) city = component.long_name;
          if (types.includes("subpremise")) unit = component.long_name;
          if (types.includes("administrative_area_level_1"))
            region = component.long_name;
          if (types.includes("country")) country = component.short_name;
        });

        setFormValues((prevValues) => ({
          ...prevValues,
          addressLine1: `${streetNumber} ${route}`,
          postalCode: postal,
          city: city,
          unitNumber: unit,
          selectedRegion: region,
          selectedCountry: country
        }));
      });
    }
  }, [isLoaded, setFormValues]);

  const handleEmailBlur = async () => {
    setEmailLoading(true);
    try {
      const response = await verifyEmail(formValues.email);
      if (response.isValid) {
        setEmailErrorMessage(null);
      } else {
        setEmailErrorMessage(response.message);
      }
    } catch (error) {
      console.log("Error validating email:", error.message);
    } finally {
      setEmailLoading(false);
    }
  };

  const handlePhoneBlur = async () => {
    setPhoneLoading(true);
    try {
      const response = await verifyPhone(
        formValues.phone,
        formValues.selectedCountry
      );
      if (response.isValid) {
        setPhoneErrorMessage(null);
      } else {
        setPhoneErrorMessage(response.message);
      }
    } catch (error) {
      console.log("Error validating phone:", error.message);
    } finally {
      setPhoneLoading(false);
    }
  };

  const handleInputChange = (field, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [field]: ""
    }));
  };

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    setFormValues((prevValues) => ({
      ...prevValues,
      phone: value
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      phone: ""
    }));
  };

  const handleUnitNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setFormValues((prevValues) => ({
      ...prevValues,
      unitNumber: value
    }));
  };

  return (
    <div className="payment-selection-container">
      <p className="title" style={{ color: "#ef7c00", marginBottom: "20px" }}>
        Your Details
      </p>
      <form action="" className="donation-form">
        <div className="details-form-row">
          <div className="input-with-icon" style={{ flexDirection: "column" }}>
            <div className="input-with-icon">
              <MdPerson className="input-icon" />
              <input
                type="text"
                id="firstName"
                name="firstName"
                placeholder="First Name *"
                maxLength="19"
                value={formValues.firstName}
                onChange={(e) => handleInputChange("firstName", e.target.value)}
                required
              />
            </div>
            {formErrors.firstName && (
              <div className="errorbox">{formErrors.firstName}</div>
            )}
          </div>

          <div className="input-with-icon" style={{ flexDirection: "column" }}>
            <div className="input-with-icon">
              <MdPerson className="input-icon" />
              <input
                type="text"
                id="middleName"
                name="middleName"
                placeholder="Middle Name"
                value={formValues.middleName}
                onChange={(e) =>
                  handleInputChange("middleName", e.target.value)
                }
              />
            </div>
          </div>

          <div className="input-with-icon" style={{ flexDirection: "column" }}>
            <div className="input-with-icon">
              <MdPerson className="input-icon" />
              <input
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Last Name *"
                value={formValues.lastName}
                onChange={(e) => handleInputChange("lastName", e.target.value)}
                required
              />
            </div>
            {formErrors.lastName && (
              <div className="errorbox">{formErrors.lastName}</div>
            )}
          </div>
        </div>

        <div
          className="details-form-row"
          style={{
            alignItems: "start",
            marginBottom: (emailErrorMessage || phoneErrorMessage) && 10
          }}
        >
          <div className="input-with-icon" style={{ flexDirection: "column" }}>
            <div className="input-with-icon">
              <MdEmail className="input-icon" />
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email *"
                value={formValues.email}
                onChange={(e) => handleInputChange("email", e.target.value)}
                onBlur={handleEmailBlur}
                required
              />
            </div>
            {formErrors.email && (
              <div className="errorbox">{formErrors.email}</div>
            )}
            {emailLoading ? (
              <CustomSpinner />
            ) : (
              emailErrorMessage && (
                <div className="errorbox">{emailErrorMessage}</div>
              )
            )}
          </div>

          <div className="input-with-icon" style={{ flexDirection: "column" }}>
            <div className="input-with-icon">
              <MdPhone className="input-icon" />
              <input
                type="text"
                id="phone"
                name="phone"
                placeholder="Phone *"
                value={formValues.phone}
                onChange={handlePhoneChange}
                onBlur={handlePhoneBlur}
                required
              />
            </div>
            {formErrors.phone && (
              <div className="errorbox">{formErrors.phone}</div>
            )}
            {phoneLoading ? (
              <CustomSpinner />
            ) : (
              phoneErrorMessage && (
                <div className="errorbox">{phoneErrorMessage}</div>
              )
            )}
          </div>
        </div>

        <div className="details-form-row">
          <div className="input-with-icon" style={{ flexDirection: "column" }}>
            <div className="input-with-icon">
              <MdLocationOn className="input-icon" />
              <input
                type="text"
                id="addressLine1"
                name="addressLine1"
                placeholder="Address Line 1 *"
                value={formValues.addressLine1}
                onChange={(e) =>
                  handleInputChange("addressLine1", e.target.value)
                }
                required
                ref={addressInputRef}
              />
            </div>
            {formErrors.addressLine1 && (
              <div className="errorbox">{formErrors.addressLine1}</div>
            )}
          </div>
        </div>

        <div className="details-form-row">
          <div className="input-with-icon" style={{ flexDirection: "column" }}>
            <div className="input-with-icon">
              <MdLocationOn className="input-icon" />
              <input
                type="text"
                id="addressLine2"
                name="addressLine2"
                placeholder="Address Line 2"
                value={formValues.addressLine2}
                onChange={(e) =>
                  handleInputChange("addressLine2", e.target.value)
                }
              />
            </div>
          </div>
        </div>

        <div className="details-form-row">
          <div className="input-with-icon" style={{ flexDirection: "column" }}>
            <div className="input-with-icon">
              <MdDoorBack className="input-icon" />
              <input
                type="text"
                id="unit"
                name="unit"
                placeholder="Unit Number"
                value={formValues.unitNumber}
                onChange={handleUnitNumberChange}
              />
            </div>
            {formErrors.unitNumber && (
              <div className="errorbox">{formErrors.unitNumber}</div>
            )}
          </div>

          <div className="input-with-icon" style={{ flexDirection: "column" }}>
            <div className="input-with-icon">
              <MdLocationCity className="input-icon" />
              <input
                type="text"
                id="city"
                name="city"
                placeholder="City *"
                value={formValues.city}
                onChange={(e) => handleInputChange("city", e.target.value)}
                required
              />
            </div>
            {formErrors.city && (
              <div className="errorbox">{formErrors.city}</div>
            )}
          </div>

          <div className="input-with-icon" style={{ flexDirection: "column" }}>
            <div className="input-with-icon">
              <MdMarkunreadMailbox className="input-icon" />
              <input
                type="text"
                id="postal"
                name="postal"
                placeholder="Postal Code / Zip *"
                value={formValues.postalCode}
                onChange={(e) =>
                  handleInputChange("postalCode", e.target.value)
                }
                required
              />
            </div>
            {formErrors.postalCode && (
              <div className="errorbox">{formErrors.postalCode}</div>
            )}
          </div>
        </div>

        <div className="details-form-row">
          <div className="input-with-icon" style={{ flexDirection: "column" }}>
            <div className="input-with-icon">
              <MdMap className="input-icon" />
              <RegionDropdown
                country={formValues.selectedCountry}
                value={formValues.selectedRegion}
                onChange={(val) => handleInputChange("selectedRegion", val)}
                required
                className="region-dropdown"
                placeholder="Select Region *"
                countryValueType="short"
              />
            </div>
            {formErrors.selectedRegion && (
              <div className="errorbox">{formErrors.selectedRegion}</div>
            )}
          </div>

          <div className="input-with-icon" style={{ flexDirection: "column" }}>
            <div className="input-with-icon">
              <MdPublic className="input-icon" />
              <CountryDropdown
                value={formValues.selectedCountry}
                onChange={(val) => handleInputChange("selectedCountry", val)}
                className="country-dropdown"
                required
                placeholder="Select Country *"
                valueType="short"
              />
            </div>
            {formErrors.selectedCountry && (
              <div className="errorbox">{formErrors.selectedCountry}</div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

const Inscription = ({ inscription, setInscription }) => {
  return (
    <div style={{ marginBottom: "50px" }}>
      <div className="details-form-row">
        <div className="input-with-icon">
          <MdOutlinePending className="input-icon" />
          <input
            value={inscription}
            onChange={(e) => setInscription(e.target.value)}
            type="text"
            id="inscription"
            name="inscription"
            placeholder="Inscription"
            maxLength={1000}
          />
        </div>
      </div>
      <p className="credit-card-info-description" style={{ textAlign: "left" }}>
        {inscription.length} out of 1000 max characters
      </p>
    </div>
  );
};

const ExtraChoice = ({
  zakatEligible,
  isZakatSelected,
  setIsZakatSelected,
  optOutOfEmailsSelected,
  setOptOutOfEmailsSelected,
  isProcessingFeeSelected,
  setIsProcessingFeeSelected
}) => {
  return (
    <div className="extraChoise-container">
      <div
        className="extraChoice-item"
        onClick={() => setOptOutOfEmailsSelected(!optOutOfEmailsSelected)}
      >
        <CustomCheckbox isSelected={optOutOfEmailsSelected} />
        <label className="extraChoice-label">
          I WANT TO OPT OUT OF RECEIVING EMAILS
        </label>
      </div>
      {zakatEligible && (
        <div
          className="extraChoice-item"
          onClick={() => setIsZakatSelected(!isZakatSelected)}
        >
          <CustomCheckbox isSelected={isZakatSelected} />
          <label className="extraChoice-label">
            I WANT TO DESIGNATE THIS DONATION AS ZAKAT
          </label>
        </div>
      )}
      <div
        className="extraChoice-item"
        onClick={() => setIsProcessingFeeSelected(!isProcessingFeeSelected)}
      >
        <CustomCheckbox isSelected={isProcessingFeeSelected} />
        <label className="extraChoice-label">
          I WANT TO COVER THE PROCESSING FEES
        </label>
      </div>
      <p className="extraChoice-description">
        Covering the fees will add $7.40 (2.2% + $0.80) to the donation total.
        Thank you for helping to keep Penny Appeal Canada running!
      </p>
    </div>
  );
};

const SubTotal = ({
  isProcessingFeeSelected,
  isUpsellSelected,
  upsell,
  handleDonateClick,
  amount,
  processingFee,
  total,
  loading,
  paymentMethod,
  paymentRequest,
  paymentKey
}) => {
  return (
    <>
      <table id="form-summary">
        <tbody>
          <tr id="subtotal">
            <td>Subtotal</td>
            <td>{parseInt(amount).toFixed(2)}</td>
          </tr>
          {isUpsellSelected && (
            <tr id="promoAmt">
              <td>Sadaqah</td>
              <td>{upsell.amount.toFixed(2)}</td>
            </tr>
          )}
          {isProcessingFeeSelected && (
            <tr id="fees">
              <td>Processing Fees (2.2% + $0.80)</td>
              <td>{processingFee}</td>
            </tr>
          )}
          <tr id="total">
            <th>Total</th>
            <th>{total}</th>
          </tr>
        </tbody>
      </table>
      <div className="gform_footer top_label">
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <CustomSpinner />
          </div>
        ) : (
          <>
            {paymentMethod === "Mobile Pay" ? (
              <CustomPaymentMethod
                paymentRequest={paymentRequest}
                total={total}
              />
            ) : (
              <CustomButton
                color={"#ef7c00"}
                label="DONATE"
                invertColorScheme
                onClick={(e) => handleDonateClick(e)}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

const Notes = () => {
  return (
    <section class="section section--checkoutDisclaimer">
      <div class="wrapper">
        <p style={{ textAlign: "left" }}>
          Note:
          <br />
          1. For Donors using Google Pay or Apple Pay, please ensure the address
          used to donate here matches your default address on your Google or
          Apple Pay account. If these addresses do not match, you will
          experience an error on this page and may be charged without receiving
          an automatic donation receipt. Please email us at info@pennyappeal.ca
          and do not process any new or consecutive donations, if you experience
          any issues. Thank you.
        </p>
        <p style={{ textAlign: "left" }}>
          2. Penny Appeal Canada is committed to managing the resources
          entrusted to us in a transparent and responsible manner which includes
          ensuring funds for countries are spent accordingly. Should Penny
          Appeal Canada receive funds which are not eligible for the designated
          programs, donations will be allocated towards areas most needed to
          safeguard other vulnerable communities we operate in.
        </p>
        <p style={{ textAlign: "left" }}>
          3. By providing your contact details above you agree to our{" "}
          <a
            href="https://pennyappeal.ca/privacy-policy/"
            target="_blank"
            rel="noopener"
          >
            Privacy Policy
          </a>
          . If you prefer not to receive any communications, please send an
          email to info@pennyappeal.ca with your contact details and the subject
          “Communication Opt-Out”.
        </p>
      </div>
    </section>
  );
};
