import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  funds: [],
  campaigns: [],
  additionalCauses: [],
  campaignBlocks: [],
  image: '/assets/pac-donate-main.jpg',
};

const fundsAndCampaignsSlice = createSlice({
  name: 'fundsAndCampaigns',
  initialState,
  reducers: {
    setFunds: (state, action) => {
      state.funds = action.payload;
    },
    setCampaigns: (state, action) => {
      state.campaigns = action.payload;
    },
    setAdditionalCauses: (state, action) => {
      state.additionalCauses = action.payload;
    },
    setCampaignBlocks: (state, action) => {
      state.campaignBlocks = action.payload;
    },
    setImage: (state, action) => {
      state.image = action.payload; 
    },
  },
});

export const { 
  setFunds, 
  setCampaigns, 
  setAdditionalCauses,
  setImage,
  setCampaignBlocks,
} = fundsAndCampaignsSlice.actions;

export default fundsAndCampaignsSlice.reducer;
