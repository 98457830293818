import React from 'react';
import './CustomCheckbox.css';

const CustomCheckbox = ({ isSelected }) => {
  return (
    <div className={`checkbox ${isSelected ? 'selected' : ''}`}>
      <span className="checkmark">&#10003;</span>
    </div>
  );
};

export default CustomCheckbox;