import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Funds from "./sections/RightSideSection/Funds/Funds";
import CampaignsAndPrices from "./sections/RightSideSection/CampaignsAndPrices/CampaignsAndPrices";
import Checkout from "./sections/RightSideSection/Checkout/Checkout";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import "./Home.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
  locale: "en-CA"
});

const Home = () => {
  const newImage = useSelector((state) => state.fundsAndCampaigns.image);
  const [currentImage, setCurrentImage] = useState(newImage); // Current image
  const [incomingImage, setIncomingImage] = useState(null); // Incoming image to fade in
  const [fadeClass, setFadeClass] = useState(""); // For animations

  const location = useLocation();

  useEffect(() => {
    if (newImage !== currentImage) {
      setIncomingImage(newImage); // Prepare the incoming image
      setFadeClass("fade-in"); // Apply fade-in class to the incoming image
    }
  }, [newImage, currentImage]);

  useEffect(() => {
    if (fadeClass === "fade-in") {
      // Once fade-in starts, wait for the animation to complete
      const timeout = setTimeout(() => {
        setCurrentImage(newImage); // Update current image
        setIncomingImage(null); // Remove incoming image from DOM
        setFadeClass(""); // Reset the fade class
      }, 300); // Match this with the CSS animation duration

      return () => clearTimeout(timeout); // Cleanup in case of rapid changes
    }
  }, [fadeClass, newImage]);

  return (
    <div id="page" className="site" >
      <div className="siteInner siteInner--hasSplit">
        {/* Static Current Image */}
        {currentImage && (
          <div
            className={`splitCol splitCol--featImg splitCol--hasFeatImg`}
            style={{
              backgroundImage: `url(${currentImage})`,
              zIndex: 1
            }}
          />
        )}

        {/* Incoming Image */}
        {incomingImage && (
          <div
            className={`splitCol splitCol--featImg splitCol--hasFeatImg ${fadeClass}`}
            style={{
              backgroundImage: `url(${incomingImage})`,
              opacity: 0,
              transition: "opacity 0.3s ease-in-out"
            }}
          />
        )}

        {/* Content Section */}
        <div className="splitCol splitCol--content">
          <Routes>
            <Route path="/" element={<Funds />} />
            <Route
              path="/checkout"
              element={
                <GoogleReCaptchaProvider
                  reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                >
                  <Elements stripe={stripePromise}>
                    <Checkout />
                  </Elements>
                </GoogleReCaptchaProvider>
              }
            />
            <Route path="/program/:fundSlug" element={<CampaignsAndPrices />} />
            <Route
              path="/program/:fundSlug/:campaignSlug"
              element={<CampaignsAndPrices />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Home;
