import axios from "axios";
import handleError from "../utils/errorHandler.js";

const baseURL = process.env.REACT_APP_BASE_API_URL;

export const fetchGiftBySubscriptionId = async (id) => {
  try {
    const response = await axios.get(`${baseURL}/gifts/giftBySubscriptionId/${id}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const fetchGiftByPaymentId = async (id) => {
  try {
    const response = await axios.get(`${baseURL}/gifts/giftByPaymentId/${id}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};
