import React from 'react';
import './Upsell.css' 
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import CustomButton from '../CustomButton/CustomButton';

const Upsell = ({
  isUpsellSelected,
  setUpsellChecked,
  upsell,
  handleUpsellRedirect,
}) => {

  return (
    <div 
      className="upsell-item" 
      onClick={() => setUpsellChecked(!isUpsellSelected)}
      style={{
        backgroundImage: upsell.backgroundImage ? `url(${upsell.backgroundImage})` : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: upsell.backgroundImage ? 'transparent' : 'white',
      }}
    >
      <div className="upsell-content">
        {
          upsell.type === 'Standard' ?
          <div className='upsell-checkbox-wrpper'>
            <CustomCheckbox isSelected={isUpsellSelected} />
            <span className="upsell-title">{upsell.title}</span>
          </div> :
          <div style={{ alignItems: 'center' }}>
            <CustomButton 
              color="#ef7c00" 
              invertColorScheme
              label="Donate" 
              onClick={() => handleUpsellRedirect()}
            />
            <p className="upsell-title">{upsell.title}</p>
          </div>
        }
        <p className="upsell-description">
          {upsell.description}
        </p>
      </div>
      {
        upsell.iconSVGCode ? (
          <div
            className="upsell-image"
            dangerouslySetInnerHTML={{ __html: upsell.iconSVGCode }}
          />
        ) : (
          <div className="upsell-image">
            <img src="/assets/gift-bg.svg" alt="Give Gift" />
          </div>
        )
      }
    </div>
  );
};

export default Upsell;
