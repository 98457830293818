import React, { useState, useEffect } from "react";
import "./PriceCard.css";

const PriceCard = ({
  iconSVGCode,
  title,
  description,
  selected,
  count,
  allowIncrements,
  isCustom,
  onClick,
  onCountChange,
  color
}) => {
  const parsedAmount = parseFloat(title.replace("$", ""));
  const [incrementAmount, setIncrementAmount] = useState(parsedAmount);
  const handleIncrement = (e) => {
    e.stopPropagation();
    onCountChange(count + 1);
    setIncrementAmount((prev) => prev + parsedAmount);
  };

  const handleDecrement = (e) => {
    e.stopPropagation();
    onCountChange(count - 1);

    if (incrementAmount - parsedAmount < parsedAmount) {
      setIncrementAmount(parsedAmount);
    } else {
      setIncrementAmount((prev) => prev - parsedAmount);
    }
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const showCounter = selected && allowIncrements && !isCustom;
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!selected) {
      setIncrementAmount(parsedAmount);
    }
  }, [selected]);
  return (
    <div
      className={`donateOption__wrapper ${
        isCustom && "donationOption__wrapper--fullWidth"
      }`}
    >
      <button
        className={`donateOption donateSelector  ${
          selected && "donateOption--selected"
        }`}
        style={{ "--color": color }}
        onClick={onClick}
      >
        {!isMobile && iconSVGCode && (
          <div
            className="svg-inline--fa"
            style={{
              ...(isCustom
                ? {
                    color: "#878786",
                    height: 40,
                    width: 40,
                    margin: "0 auto 0.7em"
                  }
                : { color: selected ? color : "#7b7b7b" })
            }}
            dangerouslySetInnerHTML={{ __html: iconSVGCode }}
          />
        )}
        {!isCustom && title && (
          <span className="donateOption__amount">
            {allowIncrements ? "$" + incrementAmount : title}
          </span>
        )}
        {description && (
          <span className="donateOption__title">{description}</span>
        )}
        <span
          className={`donateOption__checkIcon`}
          style={{ backgroundColor: color }}
        >
          <svg
            className="svg-inline--fa"
            aria-hidden="true"
            focusable="false"
            data-prefix="far"
            data-icon="check"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            data-fa-i2svg=""
          >
            <path
              fill="currentColor"
              d="M441 103c9.4 9.4 9.4 24.6 0 33.9L177 401c-9.4 9.4-24.6 9.4-33.9 0L7 265c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l119 119L407 103c9.4-9.4 24.6-9.4 33.9 0z"
            ></path>
          </svg>
        </span>
      </button>
      <div
        className="donateOption__shares"
        style={{ display: showCounter ? "block" : "none" }}
      >
        <div className="numberSelect">
          <button
            className="numberSelect__btn numberSelect__btn--decrease"
            style={{
              "--color": color,
              cursor: "pointer"
            }}
            onClick={handleDecrement}
          >
            <svg
              className="svg-inline--fa fa-minus"
              aria-hidden="true"
              focusable="false"
              data-prefix="far"
              data-icon="minus"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              data-fa-i2svg=""
            >
              <path
                fill="currentColor"
                d="M432 256c0 13.3-10.7 24-24 24L40 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l368 0c13.3 0 24 10.7 24 24z"
              ></path>
            </svg>
          </button>
          <div className="numberSelect__number">{count}</div>
          <button
            className="numberSelect__btn numberSelect__btn--increase"
            data-change="1"
            style={{
              "--color": color,
              cursor: "pointer"
            }}
            onClick={handleIncrement}
          >
            <svg
              className="svg-inline--fa fa-plus"
              aria-hidden="true"
              focusable="false"
              data-prefix="far"
              data-icon="plus"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              data-fa-i2svg=""
            >
              <path
                fill="currentColor"
                d="M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 160L40 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l160 0 0 160c0 13.3 10.7 24 24 24s24-10.7 24-24l0-160 160 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-160 0 0-160z"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PriceCard;
