import axios from 'axios';
import handleError from '../utils/errorHandler.js';

const baseURL = process.env.REACT_APP_BASE_API_URL;

export const fetchCampaigns = async () => {
  try {
    const response = await axios.post(`${baseURL}/campaigns/`, {
      active: true,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const fetchCustomCampaigns = async () => {
  try {
    const response = await axios.post(`${baseURL}/campaigns/customCampaigns`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};