import axios from 'axios';
import handleError from '../utils/errorHandler.js';

const baseURL = process.env.REACT_APP_BASE_API_URL;

export const verifyEmail = async (email) => {
  try {
    const response = await axios.post(`${baseURL}/verification/email`, {
      email,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const verifyRecaptcha = async (token) => {
  try {
    const response = await axios.post(`${baseURL}/verification/recaptcha`, {
      token,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const verifyPhone = async (phoneNumber, countryCode) => {
  try {
    const response = await axios.post(`${baseURL}/verification/phone`, {
      phoneNumber,
      countryCode,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};
