import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Provider } from "react-redux";
import store from "../store";
import Home from "../pages/Home/Home";
import ThankYou from "../pages/ThankYou/ThankYou";
import { fetchFunds } from "../api/funds";
import { fetchCampaigns, fetchCustomCampaigns } from "../api/campaigns";
import {
  setFunds,
  setCampaigns,
  setAdditionalCauses,
  setCampaignBlocks
} from "../store/slices/fundsAndCampaignsSlice";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "../utils/scrollToTop";
import Preloader from "../components/Preloader/Preloader";

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadFundsAndCampaigns = async () => {
      const { funds } = await fetchFunds();
      const { campaigns } = await fetchCampaigns();
      const { campaigns: customCampaigns } = await fetchCustomCampaigns();

      dispatch(setFunds(funds));
      dispatch(setCampaigns(campaigns));
      dispatch(setCampaignBlocks(customCampaigns));
      dispatch(
        setAdditionalCauses(
          campaigns.filter((campaign) => campaign.additionalCause)
        )
      );
      setLoading(false);
    };

    loadFundsAndCampaigns();
  }, [dispatch]);

  if (loading) {
    return <Preloader />;
  }

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route
          path="/thank-you/:fundSlug/:campaignSlug"
          element={<ThankYou />}
        />
      </Routes>
    </Router>
  );
}

export default function RootApp() {
  return (
    <Provider store={store}>
      <App />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Provider>
  );
}
