import React from "react";
import "./Preloader.css";

export default function Preloader() {
  return (
    <div class="preloader">
      <div className="spinner-white" />
      <div class="preloader__inner">
        <div class="preloader__text">
          Small Change.
          <br />
          <b>Big Difference.</b>
        </div>
      </div>
    </div>
  );
}
