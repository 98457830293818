const handleError = (error) => {
  if (error.response) {
    // Server responded with a status other than 2xx
    console.log('Server Error:', error.response.data.message || error.response.data);
    throw new Error(error.response.data.message || 'Server Error');
  } else if (error.request) {
    // No response was received from the server
    console.log('Network Error: No response received from the server.');
    throw new Error('Network Error: Please check your internet connection.');
  } else {
    // Error setting up the request
    console.log('Error:', error.message);
    throw new Error('Request Error: Something went wrong while setting up the request.');
  }
};

export default handleError;