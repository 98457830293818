import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Upsell from "../../components/Upsell/Upsell";
import Preloader from "../../components/Preloader/Preloader";
import moment from "moment/moment";
import {
  fetchGiftByPaymentId,
  fetchGiftBySubscriptionId
} from "../../api/gift";

const ThankYou = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isUpsellSelected, setUpsellChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [gift, setGift] = useState(null);
  const [loadingGift, setLoadingGift] = useState(true);

  const funds = useSelector((state) => state.fundsAndCampaigns.funds);
  const campaigns = useSelector((state) => state.fundsAndCampaigns.campaigns);
  const featuredFunds = funds.filter(
    (fund) => fund.featureOnThankYouPage?.active
  );
  const featuredFundAmount = 10;
  const { fundSlug, campaignSlug } = useParams();

  const params = useParams();

  const currentFund = funds.find((fund) => fund.slug === fundSlug);
  const currentCampaign = campaigns.find(
    (campaign) =>
      campaign.slug === campaignSlug && campaign.fundId === currentFund.fundId
  );
  const { upsell } = currentCampaign || {};
  const searchParams = new URLSearchParams(location.search);
  const firstName = searchParams.get("firstName");
  const amount = searchParams.get("amount");

  const handleFundClick = (fund) => {
    const featuredCampaign = campaigns.find(
      (campaign) =>
        campaign.campaignId === fund.featureOnThankYouPage.campaignId
    );
    const featuredFund = funds.find(
      (fund) => fund.fundId === featuredCampaign.fundId
    );

    const reSplit = `${featuredFundAmount},${featuredFund.fundId},${featuredCampaign.campaignId}`;
    const amount = parseFloat(featuredFundAmount).toFixed(2);
    const frequency = "monthly";
    const details = `${featuredCampaign.name} X 1 ($${featuredFundAmount})`;

    const queryParams = new URLSearchParams({
      donation: encodeURIComponent(featuredCampaign.name),
      re_split: reSplit,
      program: encodeURIComponent(featuredFund.name),
      amount,
      frequency,
      details: encodeURIComponent(details)
    }).toString();

    navigate(`/checkout?${queryParams}`);
  };

  const handleUpsellRedirect = () => {
    const donation = campaigns.find(
      (campaign) => campaign.campaignId === upsell.campaignId
    ).name;
    const reSplit = `${upsell.amount},${upsell.fundId},${upsell.campaignId}`;
    const program = funds.find(
      (fund) =>
        fund.fundId ===
        campaigns.find((campaign) => campaign.campaignId === upsell.campaignId)
          .fundId
    ).name;
    const amount = parseFloat(upsell.amount).toFixed(2);
    const frequency = "One Time";
    const details = `${
      campaigns.find((campaign) => campaign.campaignId === upsell.campaignId)
        .name
    } X 1 ($${upsell.amount})`;

    const queryParams = new URLSearchParams({
      donation: encodeURIComponent(donation),
      re_split: reSplit,
      program: encodeURIComponent(program),
      amount,
      frequency,
      details: encodeURIComponent(details)
    }).toString();

    navigate(`/checkout?${queryParams}`);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchGift = async () => {
    const subscriptionIds = searchParams.get("subscriptionIds");
    const paymentIntentId = searchParams.get("paymentIntentId");

    try {
      let req;
      if (paymentIntentId) {
        req = await fetchGiftByPaymentId(paymentIntentId);
        console.log("Fetching gift by Payment Intent ID:", paymentIntentId);
      } else if (subscriptionIds) {
        req = await fetchGiftBySubscriptionId(subscriptionIds);
        console.log("Fetching gift by Subscription IDs:", subscriptionIds);
      }

      if (req && req.gift) {
        console.log("Gift found:", req.gift);
        setGift(req.gift);
        setLoadingGift(false);
      } else {
        console.log("Gift not found yet.");
      }
    } catch (error) {
      console.error("Error fetching gift:", error);
    }
  };

  useEffect(() => {
    if (upsell && upsell.type === "Secondary") {
      const timer = setTimeout(() => {
        setIsModalOpen(true);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [upsell]);

  useEffect(() => {
    let intervalId;
    let attempts = 0;
    const maxAttempts = 10; // Maximum number of polling attempts (e.g., 10 * 3s = 30s)

    const startPolling = () => {
      intervalId = setInterval(async () => {
        attempts += 1;
        console.log(`Polling attempt ${attempts} for gift...`);
        await fetchGift();

        if (gift) {
          console.log("Gift is now available. Stopping polling.");
          clearInterval(intervalId);
        }

        if (attempts >= maxAttempts) {
          console.log("Max polling attempts reached. Stopping polling.");
          clearInterval(intervalId);
          setLoadingGift(false);
        }
      }, 3000); // Poll every 3 seconds
    };

    if (!gift) {
      startPolling();
    }

    return () => clearInterval(intervalId);
  }, [gift]); // Dependency on 'gift'

  if (loadingGift) {
    return <Preloader />;
  }

  return (
    <div id="page-id-210">
      <div id="page" className="site">
        <a className="skip-link screenReaderText" href="#primary">
          Skip to content
        </a>

        <header id="masthead" className="siteHeader">
          <div className="siteHeader__inner">
            <a href="https://www.pennyappeal.ca/" className="siteLogo__wrapper">
              <img
                width="440"
                height="104"
                src="https://donate.pennyappeal.ca/wp-content/uploads/2021/08/pac-horizontallogo-white.svg"
                className="siteLogo siteLogo--white"
                alt=""
                decoding="async"
              />
              <img
                width="440"
                height="104"
                src="https://donate.pennyappeal.ca/wp-content/uploads/2021/08/pac-horizontallogo-white.svg"
                className="siteLogo siteLogo--default"
                alt=""
                decoding="async"
              />
            </a>
          </div>
        </header>

        <div className="siteInner">
          <main id="primary" className="siteMain">
            <div
              className="pageHeader pageHeader--thankYou"
              style={{
                "--mobile-img":
                  "url(https://donate.pennyappeal.ca/wp-content/uploads/2023/12/Rectangle-1.png)",
                "--desktop-img":
                  "url(https://donate.pennyappeal.ca/wp-content/uploads/2023/12/Hero-BG.png)"
              }}
            >
              <div
                className="pageHeader__inner"
                data-gtm-vis-recent-on-screen11440330_126="210"
                data-gtm-vis-first-on-screen11440330_126="210"
                data-gtm-vis-total-visible-time11440330_126="100"
                data-gtm-vis-has-fired11440330_126="1"
                data-gtm-vis-recent-on-screen11440330_131="212"
                data-gtm-vis-first-on-screen11440330_131="212"
                data-gtm-vis-total-visible-time11440330_131="100"
                data-gtm-vis-has-fired11440330_131="1"
              >
                <h1 className="h1--large">
                  Thank You <br />
                  <span>{firstName}</span>
                </h1>
                <h3>
                  for donating ${amount} to <span>{currentFund?.name}</span>
                </h3>
              </div>
            </div>

            {/* Social Sharing */}
            <div className="socialBox mobile">
              <h4 className="socialBox__title">Share your impact</h4>
              <ul className="socials socials--extraLarge socialBox__socials">
                <li className="social social--facebook">
                  <a
                    href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.pennyappeal.ca"
                    target="_blank"
                    className="social__icon"
                    rel="noopener noreferrer"
                  >
                    <svg
                      className="svg-inline--fa fa-facebook-f"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="facebook-f"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"
                      ></path>
                    </svg>
                    {/* <i className="fab fa-facebook-f"></i> */}
                  </a>
                </li>
                <li className="social social--twitter">
                  <a
                    href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.pennyappeal.ca%2F&amp;text=I+donated+to+Penny+Appeal+Canada%27s+fundraising+program.+You+can+make+a+difference+too%21"
                    target="_blank"
                    className="social__icon"
                    rel="noopener noreferrer"
                  >
                    <svg
                      className="svg-inline--fa fa-x-twitter"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="x-twitter"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                      ></path>
                    </svg>
                    {/* <i className="fab fa-x-twitter"></i> */}
                  </a>
                </li>
                <li className="social social--linkedin">
                  <a
                    href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fwww.pennyappeal.ca"
                    target="_blank"
                    className="social__icon"
                    rel="noopener noreferrer"
                  >
                    <svg
                      className="svg-inline--fa fa-linkedin-in"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="linkedin-in"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                      ></path>
                    </svg>
                    {/* <i className="fab fa-linkedin-in"></i> */}
                  </a>
                </li>
              </ul>
            </div>

            <div className="thankyou__main">
              {/* Social Sharing */}
              <div className="socialBox">
                <h4 className="socialBox__title">Share your impact</h4>
                <ul className="socials socials--extraLarge socialBox__socials">
                  <li className="social social--facebook">
                    <a
                      href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.pennyappeal.ca"
                      target="_blank"
                      className="social__icon"
                      rel="noopener noreferrer"
                    >
                      <svg
                        className="svg-inline--fa fa-facebook-f"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="facebook-f"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"
                        ></path>
                      </svg>
                      {/* <i className="fab fa-facebook-f"></i> */}
                    </a>
                  </li>
                  <li className="social social--twitter">
                    <a
                      href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.pennyappeal.ca%2F&amp;text=I+donated+to+Penny+Appeal+Canada%27s+fundraising+program.+You+can+make+a+difference+too%21"
                      target="_blank"
                      className="social__icon"
                      rel="noopener noreferrer"
                    >
                      <svg
                        className="svg-inline--fa fa-x-twitter"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="x-twitter"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                        ></path>
                      </svg>
                      {/* <i className="fab fa-x-twitter"></i> */}
                    </a>
                  </li>
                  <li className="social social--linkedin">
                    <a
                      href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fwww.pennyappeal.ca"
                      target="_blank"
                      className="social__icon"
                      rel="noopener noreferrer"
                    >
                      <svg
                        className="svg-inline--fa fa-linkedin-in"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="linkedin-in"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        data-fa-i2svg=""
                      >
                        <path
                          fill="currentColor"
                          d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                        ></path>
                      </svg>
                      {/* <i className="fab fa-linkedin-in"></i> */}
                    </a>
                  </li>
                </ul>
              </div>

              {/* Content area */}
              <div className="thankyou__content">
                <h2>
                  Thank you for your donation.
                  <br />
                  You will receive an email confirmation soon.
                </h2>
                <p>
                  Penny Appeal Canada is a relief and development organization
                  with a really BIG vision for the world. We want to create the
                  best societies we can and break the cycles of need and poverty
                  for good with our long term sustainable solution approach.
                  That’s why we work to turn small change into a BIG DIFFERENCE.
                </p>
                <div className="upsell__beforeContent">
                  <div className="orange-vector"></div>
                  <h2 style={{ color: "#ef7c00" }}>Make an ongoing impact</h2>
                  <p>
                    Donate an extra $10 monthly to one of our programs below.
                  </p>
                  <div className="donationBlock__wrapper">
                    <div className="donationBlock donationBlock--light donationBlock--upsells donationBlock--monthlyProgramUpsells">
                      <div className="donateOptions">
                        {featuredFunds &&
                          featuredFunds.length > 0 &&
                          featuredFunds.map((featured) => (
                            <div className="donateOption__wrapper">
                              <a onClick={() => handleFundClick(featured)}>
                                <button
                                  className="donateOption donateSelector donateOption"
                                  style={{
                                    boxShadow: `0px 0px 20px ${featured.color}`,
                                    background: featured.color
                                  }}
                                >
                                  {featured.iconSVGCode && (
                                    <div
                                      className="svg-inline--fa fa-engine-warning causeOption__icon"
                                      dangerouslySetInnerHTML={{
                                        __html: featured.iconSVGCode
                                      }}
                                    />
                                  )}
                                  <span className="donateOption__title">
                                    {featured.name}
                                  </span>
                                </button>
                              </a>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Upselling */}
              <div className="thankyou__upsells">
                {/* Add upsell content here if needed */}
              </div>
            </div>
            <div className="thankyou__upsells">
              <section className="paddingTop--regular paddingBottom--regular section section--donationDetails">
                <div className="content">
                  <h2>Donation Details</h2>
                  <p>
                    You should receive a copy of this information in your email
                    confirmation.
                  </p>
                  <a
                    href="https://donate.pennyappeal.ca/"
                    className="btn--primary btn--orange thankYouCTA__btn"
                  >
                    Make another donation
                  </a>
                </div>
                {gift && (
                  <div className="donationReceipt summary">
                    <table>
                      <tbody>
                        <tr>
                          <td>Donor Name</td>
                          <td>{firstName}</td>
                        </tr>
                        <tr>
                          <td>Date</td>
                          <td>{moment().format("LL")}</td>
                        </tr>
                        <tr>
                          <td>Program</td>
                          <td>{currentFund?.name}</td>
                        </tr>
                        <tr>
                          <td>Total Donation</td>
                          <td>${gift?.amount}</td>
                        </tr>
                        <tr>
                          <td>Frequency</td>
                          <td
                            style={{
                              textTransform: "capitalize"
                            }}
                          >
                            {gift?.recurringGiftSchedule &&
                            gift?.recurringGiftSchedule.length > 0
                              ? `$${
                                  gift?.amount
                                }/${gift.recurringGiftSchedule[0].frequency.toLowerCase()}`
                              : "One Time"}
                          </td>
                        </tr>
                        {gift?.recurringGiftSchedule &&
                          gift?.recurringGiftSchedule.length > 0 && (
                            <tr>
                              <td>Next Payment</td>
                              <td>
                                {moment(
                                  gift.recurringGiftSchedule[0]
                                    .nextTransactionDate
                                ).format("LL")}
                              </td>
                            </tr>
                          )}
                        <tr>
                          <td>Cause</td>
                          <td>{currentCampaign?.name}</td>
                        </tr>
                        <tr>
                          <td>Donation ID</td>
                          <td>{gift?.reGiftId}</td>
                        </tr>
                        <tr>
                          <td>Payment Method</td>
                          <td>Credit Card</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
                <a
                  href="https://donate.pennyappeal.ca/"
                  className="btn--primary btn--orange thankYouCTA__btn alt"
                >
                  Make another donation
                </a>
              </section>
            </div>
          </main>
        </div>

        <div
          className="thankyou__footer"
          style={{
            "--mobile-img":
              "url(https://donate.pennyappeal.ca/wp-content/uploads/2023/12/Rectangle-3-1.png)",
            "--desktop-img":
              "url(https://donate.pennyappeal.ca/wp-content/uploads/2023/12/Rectangle-3.png)"
          }}
        >
          <div className="content">
            <h2>Learn how your donations</h2>
            <p>Make a difference</p>
            <a
              href="https://pennyappeal.ca/about/reports"
              className="btn--primary btn--white thankYouCTA__btn"
            >
              Read our reports
            </a>
          </div>

          <footer id="colophone" className="siteFooter">
            <div className="siteFooter__inner">
              <div className="copyright">
                <p
                  style={{
                    color: "white"
                  }}
                >
                  Penny Appeal is a registered charity with the Canada Revenue
                  Agency. <span>CRA no. 827502741 RR0001</span>
                </p>
                <p>
                  <a
                    href="https://fikralabs.ca"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "white"
                    }}
                  >
                    Website Made With{" "}
                    <svg
                      className="svg-inline--fa fa-heart"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="far"
                      data-icon="heart"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8l0-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5l0 3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20-.1-.1s0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5l0 3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2l0-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"
                      ></path>
                    </svg>
                    {/* <i className="far fa-heart"></i> */} by{" "}
                    <span>Fikra Labs.</span>
                  </a>
                </p>
              </div>
            </div>
          </footer>
        </div>
      </div>
      {upsell &&
        upsell.type === "Secondary" &&
        (
          !upsell.startDate ||
          !upsell.endDate ||
          (new Date() >= new Date(upsell.startDate) &&
            new Date() <= new Date(upsell.endDate))
        )(
          <div className={`modal ${isModalOpen ? "modal-open" : ""}`}>
            <div className="modal-content">
              <h2 className="modal-title">
                Thank You For Your Donation{firstName && `, ${firstName}`}
              </h2>
              <button className="close-button" onClick={closeModal}>
                ×
              </button>
              <Upsell
                isUpsellSelected={isUpsellSelected}
                setUpsellChecked={setUpsellChecked}
                upsell={upsell}
                handleUpsellRedirect={handleUpsellRedirect}
              />
            </div>
          </div>
        )}
    </div>
  );
};

export default ThankYou;
