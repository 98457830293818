import axios from 'axios';
import handleError from '../utils/errorHandler.js';

const baseURL = process.env.REACT_APP_BASE_API_URL;

export const fetchFunds = async () => {
  try {
    const response = await axios.post(`${baseURL}/funds/`, {
      active: true,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};