import React, { useEffect, useState } from "react";
import "./PriceScreen.css";
import PriceCard from "../../../../../../components/PriceCard/PriceCard";
import CustomButton from "../../../../../../components/CustomButton/CustomButton";
import CustomCheckbox from "../../../../../../components/CustomCheckbox/CustomCheckbox";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { hexToRgbA } from "../../../../../../components/FundAndCampaignCard/FundAndCampaignCard";

const PriceScreen = ({ currentCampaign, fundName, color, isCustomBlock }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationParts = location?.pathname?.split("/");
  const slug =
    locationParts && locationParts.length > 3 ? locationParts[3] : "";

  const funds = useSelector((state) => state.fundsAndCampaigns.funds);
  const campaigns = useSelector((state) => state.fundsAndCampaigns.campaigns);
  const additionalCauses = useSelector(
    (state) => state.fundsAndCampaigns.additionalCauses
  );

  const [selectedPricePoints, setSelectedPricePoints] = useState([]);
  const [customAmount, setCustomAmount] = useState(null);
  const [recurringOption, setRecurringOption] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  let ourCampaign = currentCampaign;

  const currentFund =
    funds.find((filter) => filter.fundId === ourCampaign.fundId) ||
    additionalCauses.find((filter) => filter.fundId === ourCampaign.fundId);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleRecurringClick = (type) => {
    setRecurringOption((prev) => (prev === type ? null : type));
  };

  const handleDonateButtonClick = () => {
    const isCustom = selectedPricePoints.some((p) => p.pricePoint.custom);
    let totalAmount = 0;
    if (isCustom) {
      totalAmount = parseInt(customAmount);
    } else {
      totalAmount = selectedPricePoints.reduce((total, item) => {
        return total + item.pricePoint.amount * item.count;
      }, 0);
    }

    let reSplitString = "";
    let redDetailsString = "";
    selectedPricePoints.forEach((selectedPricePoint) => {
      console.log(selectedPricePoint);
      reSplitString += `${selectedPricePoint.pricePoint.amount},${
        currentFund.fundId
      },${
        selectedPricePoint.pricePoint.customPrice &&
        selectedPricePoint.pricePoint.customCampaignId
          ? selectedPricePoint.pricePoint.customCampaignId
          : ourCampaign.campaignId
      };`;
      redDetailsString += `${
        selectedPricePoint.pricePoint.description.length > 0
          ? selectedPricePoint.pricePoint.description
          : ourCampaign.name
      } X ${selectedPricePoint.count} ($${
        selectedPricePoint.pricePoint.amount ?? totalAmount
      }); `;
    });
    reSplitString = reSplitString.slice(0, -1);
    redDetailsString = redDetailsString.slice(0, -2);
    const donation = ourCampaign.name;
    const reSplit = reSplitString;
    const program = currentFund.name;
    const amount = parseFloat(totalAmount).toFixed(2);
    const frequency = recurringOption ? recurringOption : "One Time";
    const details = redDetailsString;

    const queryParams = new URLSearchParams({
      donation: encodeURIComponent(donation),
      re_split: reSplit,
      program: encodeURIComponent(program),
      amount,
      frequency,
      details: encodeURIComponent(details)
    }).toString();

    navigate(`/checkout?${queryParams}`);
  };

  const isCustomPricePointSelected = selectedPricePoints.find(
    (selectedPricePoints) => selectedPricePoints.pricePoint.custom
  );

  return (
    <>
      <section className="paddingBottom--regular section--donationBlock">
        <div className="donationBlock__wrapper">
          <div className="donationBlock donationBlock--light">
            <h3 className="donationBlock__title">Choose an amount to give</h3>
            <div className="donateOptions">
              <PriceList
                isCustomBlock={isCustomBlock}
                currentCampaign={ourCampaign}
                selectedPricePoints={selectedPricePoints}
                setSelectedPricePoints={setSelectedPricePoints}
                setCustomAmount={setCustomAmount}
                recurringOption={recurringOption}
              />
            </div>
            <div className="donationBlock__extra">
              {!isCustomPricePointSelected &&
              (!ourCampaign?.priceOptions?.allowCustomButtons ||
                isCustomBlock) &&
              (ourCampaign?.priceOptions?.allowMonthly ||
                ourCampaign?.priceOptions?.allowAnnually) ? (
                <>
                  {ourCampaign.priceOptions.allowMonthly && (
                    <MakeItRecurring
                      type="monthly"
                      color={ourCampaign?.color}
                      isSelected={recurringOption === "monthly"}
                      onClick={() => handleRecurringClick("monthly")}
                    />
                  )}
                  {ourCampaign?.priceOptions?.allowAnnually && (
                    <MakeItRecurring
                      type="annually"
                      color={ourCampaign?.color}
                      isSelected={recurringOption === "annually"}
                      onClick={() => handleRecurringClick("annually")}
                    />
                  )}
                </>
              ) : null}
            </div>
            <div className="donationBlock__footer">
              <div className="donationBlock__footer__main">
                {selectedPricePoints?.some((p) => p.pricePoint.custom) && (
                  <CustomAmountInput
                    customAmount={customAmount}
                    setCustomAmount={setCustomAmount}
                  />
                )}
                <div class="donationBlock__total__title">Total</div>
                <div className="donationBlock__total__wrapper">
                  {selectedPricePoints.length === 0 ||
                  (selectedPricePoints.some((p) => p.pricePoint.custom) &&
                    !customAmount) ? (
                    <span className="donationBlock__total donationBlock__total--placeholder">
                      Choose an option
                    </span>
                  ) : (
                    <span
                      className="donationBlock__total donationBlock__total--full"
                      style={{ display: "inline" }}
                    >
                      <span
                        className="donationBlock__total__amount"
                        style={{ transform: "translate(0px, 0px)" }}
                      >
                        $
                        {customAmount ||
                          selectedPricePoints.reduce(
                            (total, p) => total + p.pricePoint.amount * p.count,
                            0
                          )}
                      </span>
                      /
                      <span className="donationBlock__total__frequency">
                        {recurringOption
                          ? recurringOption === "monthly"
                            ? "Monthly"
                            : "Annually"
                          : "One Time"}
                      </span>
                    </span>
                  )}
                </div>
              </div>
              <TotalAmountAndButton
                color={color}
                selectedPricePoints={selectedPricePoints}
                customAmount={customAmount}
                recurringOption={recurringOption}
                handleDonateButtonClick={handleDonateButtonClick}
              />
            </div>
          </div>
        </div>
      </section>

      {ourCampaign?.description && (
        <section class="paddingBottom--regular section section--causeInfo">
          <div class="wrapper">
            <p class="causeInfo__program">{fundName}</p>
            <h2
              style={{
                "--color": ourCampaign?.color
              }}
            >
              {ourCampaign?.name}
            </h2>

            <p>{ourCampaign?.description}</p>

            <p>
              <a
                href={`https://pennyappeal.ca/${ourCampaign?.slug}/`}
                class="btn--tertiary btn--lightGrey learnMoreBtn"
                target="_blank"
              >
                Learn More
              </a>
            </p>
          </div>
        </section>
      )}
      <section class="section section--causeImg">
        <img src={ourCampaign?.image} alt="" />
      </section>
    </>
  );
};

const PriceList = ({
  currentCampaign,
  selectedPricePoints,
  setSelectedPricePoints,
  setCustomAmount,
  recurringOption,
  isCustomBlock
}) => {
  const { priceOptions } = currentCampaign;

  const handlePriceClick = (pricePoint) => {
    setCustomAmount(null);
    console.log(pricePoint);
    if (
      selectedPricePoints &&
      selectedPricePoints.length > 0 &&
      selectedPricePoints[0].pricePoint.pricePointId === pricePoint.pricePointId
    ) {
      setSelectedPricePoints([]);
      return;
    }
    if (pricePoint.custom) {
      setSelectedPricePoints([{ pricePoint, count: 1 }]);
    } else {
      if (selectedPricePoints.some((p) => p.pricePoint.custom)) {
        setSelectedPricePoints([{ pricePoint, count: 1 }]);
      } else if (priceOptions?.allowMultipleSelections) {
        const existing = selectedPricePoints.find(
          (p) => p.pricePoint === pricePoint
        );
        if (existing) {
          setSelectedPricePoints(
            selectedPricePoints.filter((p) => p.pricePoint !== pricePoint)
          );
        } else {
          setSelectedPricePoints([
            ...selectedPricePoints,
            { pricePoint, count: 1 }
          ]);
        }
      } else {
        setSelectedPricePoints([{ pricePoint, count: 1 }]);
      }
    }
  };

  const updateCount = (pricePoint, newCount) => {
    if (newCount <= 0) {
      setSelectedPricePoints(
        selectedPricePoints.filter((p) => p.pricePoint !== pricePoint)
      );
    } else {
      setSelectedPricePoints(
        selectedPricePoints.map((p) =>
          p.pricePoint === pricePoint ? { ...p, count: newCount } : p
        )
      );
    }
  };

  return (
    <>
      {priceOptions?.pricePoints &&
        priceOptions?.pricePoints?.map((pricePoint) => {
          const selectedItem = selectedPricePoints.find(
            (p) => p.pricePoint === pricePoint
          );
          const selected = Boolean(selectedItem);
          const count = selectedItem ? selectedItem.count : 1;
          // eslint-disable-next-line
          if (
            isCustomBlock &&
            priceOptions.allowCustomButtons &&
            !pricePoint.customPrice
          )
            return;
          if (
            !isCustomBlock &&
            priceOptions.allowCustomButtons &&
            pricePoint.customPrice
          )
            return;
          if (recurringOption && pricePoint.custom) return;
          return (
            <PriceCard
              key={pricePoint.pricePointId}
              iconSVGCode={pricePoint.iconSVGCode}
              title={pricePoint.title}
              description={pricePoint.description}
              amount={pricePoint.amount}
              selected={selected}
              count={count}
              allowIncrements={pricePoint.allowIncrements}
              isCustom={pricePoint.custom}
              onClick={() => handlePriceClick(pricePoint)}
              onCountChange={(newCount) => updateCount(pricePoint, newCount)}
              color={currentCampaign.color}
            />
          );
        })}
    </>
  );
};

const MakeItRecurring = ({ type, isSelected, color, onClick }) => (
  <label className="checkOption donationBlock__extraFrequency">
    <input
      type="checkbox"
      className="checkOption__input"
      data-type="Monthly"
      data-calculations="default"
      onClick={onClick}
      checked={isSelected}
      name="frequency_monthly"
      style={{
        "--color": color
      }}
    />
    <span className="checkOption__title">{`Make it ${type.toUpperCase()}`}</span>
    <span className="checkOption__desc">
      Automatically donate {type} to support the cause.
    </span>
  </label>
);

const CustomAmountInput = ({ customAmount, setCustomAmount }) => {
  const handleChange = (e) => {
    const value = e.target.value;
    if (value === "" || parseFloat(value) >= 0) {
      setCustomAmount(value);
    }
  };

  return (
    <span
      className="donationBlock__total__input__holder"
      style={{ display: "inline" }}
    >
      <svg
        className="svg-inline--fa fa-dollar-sign donationBlock__total__input__icon"
        aria-hidden="true"
        focusable="false"
        data-prefix="far"
        data-icon="dollar-sign"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
        data-fa-i2svg=""
      >
        <path
          fill="currentColor"
          d="M184 24c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 47.7c-3.1 .1-6.2 .3-9.3 .6c-23.2 1.9-47.2 7.4-67.2 20.1C38.7 105.6 23.5 126 18 154.2c-3.9 20.3-2 38.8 6.1 55.2c8 16 20.9 27.6 34.8 36.2c26.2 16.2 61.9 25.3 92.9 33.2l2.3 .6c33.9 8.6 62.6 16.1 81.7 28c9 5.6 14.3 11.2 17.1 16.9c2.7 5.3 4.2 12.8 2 24.5c-2.9 14.7-13.4 26.9-34.5 34.9c-21.6 8.2-52 10.9-87.6 5.9c-22.6-3.3-61.8-12.7-83-22.1c-12.1-5.4-26.3 .1-31.7 12.2s.1 26.3 12.2 31.7C57 423.2 101.1 433.4 126 437l.1 0c3.3 .5 6.6 .9 9.9 1.2l0 49.7c0 13.3 10.7 24 24 24s24-10.7 24-24l0-48.6c19.3-1.3 37.4-4.9 53.5-11c31.9-12.1 57.7-35.2 64.5-70.6c3.9-20.3 2-38.8-6.1-55.2c-8-16-20.9-27.6-34.8-36.2c-26.2-16.2-61.9-25.3-92.9-33.2l-2.3-.6c-33.9-8.6-62.6-16.1-81.7-28c-9-5.6-14.3-11.2-17.1-16.9c-2.7-5.3-4.2-12.8-2-24.5c2.9-14.8 10.1-24 20.2-30.4c10.9-6.9 26.3-11.2 45.3-12.7c38.3-3.1 83.1 5.3 113.9 12.5c12.9 3.1 25.8-4.9 28.9-17.8S268.4 89 255.5 86c-18.7-4.4-44.2-9.7-71.5-12.5L184 24z"
        ></path>
      </svg>
      <input
        type="number"
        min="0"
        max="999999999999"
        placeholder="Enter a custom amount"
        value={customAmount}
        onChange={handleChange}
        id="donationCustomAmount"
        className="donationBlock__total__input analyticDonationBlockTotalInput"
        aria-label="Enter customer donation amount"
      />
    </span>
  );
};

const TotalAmountAndButton = ({
  color,
  selectedPricePoints,
  customAmount,
  recurringOption,
  handleDonateButtonClick
}) => {
  return (
    <div className="donationBlock__btn__wrapper">
      <a
        onClick={handleDonateButtonClick}
        className="btn donationBlock__btn"
        style={{
          "--hexColor": color,
          "--rgbColor": hexToRgbA(color)
        }}
        disabled={
          selectedPricePoints.length === 0 ||
          (selectedPricePoints.some((p) => p.pricePoint.custom) &&
            !customAmount)
        }
      >
        Donate
      </a>
    </div>
  );
};

export default PriceScreen;
