import React from "react";
import "./CustomButton.css";
import { hexToRgbA } from "../FundAndCampaignCard/FundAndCampaignCard";

const CustomButton = ({
  color,
  label,
  invertColorScheme = false,
  onClick,
  disabled = false
}) => {
  return (
    <input
      type="submit"
      id="gform_submit_button_1"
      className="gform_button button"
      style={{
        "--hexColor": color,
        "--rgbColor": hexToRgbA(color),
        margin: "0 auto"
      }}
      value={label}
      disabled={disabled}
      onClick={disabled ? null : onClick}
    ></input>
  );
};

export default CustomButton;
